<template>
  <div class=" padre" >
    <div class="container-fluid ">
      <div class=" col-sm-12 mt-4 ">
        <iframe class="video" width="100%" height="300px" src="https://www.youtube.com/embed/PT71KH6JDk4"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>  
    <div class="row mt-5 mb-5">
      <div class="col-md-4 col-sm-12 ">
        <iframe  src="https://www.youtube.com/embed/GTaa89i1Yyk" 
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="col-md-4 col-sm-12 ">
        <iframe  src="https://www.youtube.com/embed/k0UF75I5I3w" 
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen></iframe>
      </div>
      <div class="col-md-4 col-sm-12">
        <iframe  src="https://www.youtube.com/embed/6c2p9zDvb_A" 
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>  
    
  </div>
    
  
</template>

<script>
import BannersVue from '../components/Banners.vue'

import FeaturesVue from '../components/Features.vue'
import MarcasVue from '../components/Marcas.vue'
import ProductsVue from '../components/Products.vue'
import SalesVue from '../components/Sales.vue'
import TestimonialsVue from '../components/Testimonials.vue'

// @ is an alias to /src

export default {
  name: 'Como_funcionamos',
  components: {
    MarcasVue,
   
    BannersVue,
    FeaturesVue,
    ProductsVue,
    SalesVue,
    TestimonialsVue
  }
}
</script>
<style >
.padre {
  background-color: #fafafa;
 
  /* IMPORTANTE */
  text-align: center;
}
.video{
  padding-left: 10px;
  padding-right: 10px;
}

</style>
