<template>
  <!-- Banner Area Start -->
    <div class="banner-area pt-100px pb-100px">
        <div class="container">
            <div class="row">
                <div class="single-col">
                    <a href="shop-left-sidebar.html" class="single-banner">
                        <img src="@/assets/images/foto_fondo/foto_fondo.png" alt="">
                        <div class="item-disc">
                            <span class="item-title">Women</span>
                            <span class="item-amount">16 items</span>
                        </div>
                    </a>
                </div>
                <div class="single-col center-col">
                    <div class="single-banner">
                        <img src="@/assets/images/foto_fondo/foto_fondo.png" alt="">
                        <div class="item-disc">
                            <h2 class="title">#bestsellers</h2>
                            <a href="shop-left-sidebar.html" class="shop-link">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div class="single-col">
                    <a href="shop-left-sidebar.html" class="single-banner">
                        <img src="@/assets/images/banner/3.jpg" alt="">
                        <div class="item-disc">
                            <span class="item-title">Nomen</span>
                            <span class="item-amount">16 items</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End -->
</template>

<script>
export default {
  name: 'banners-component'
}
</script>

<style>

</style>
